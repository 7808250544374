/**
 * Filter should persist over page switches:
 * - filterIndexList for std dishes should persist
 * - filterPersonlizedName for personlized dishes should persist
 */

import DishText from "./DishText";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  loggedIn,
  jotaiFilterIndexList,
  jotaiFilterPersonlizedName,
  jotaiSelectedFilterList,
  jotaiAllSelectedProp,
  jotaiFilterPersonlizedIndex,
} from "./JotaiStore";
import { getDishes, getList } from "./common/httpRequests";
import DialogTitle from "@mui/material/DialogTitle";
import FilterDialog from "./FilterDialog";
import { Dialog } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { megaCustom } from "./styles/theme";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { FilterTexts } from "./FilterTexts";

const filter = [
  "Potatis",
  "Nötkött",
  "Fläskkött",
  "Pasta",
  "Skaldjur",
  "Fisk",
  "Ris",
  "Kyckling",
];

export default function Generator() {
  const [deish, setDeish] = useState("");
  const [dishList, setDishList] = useState([]);
  const [index, setIndex] = useState(0);
  const [loggedInState, setLoggedInState] = useAtom(loggedIn);
  const [filterIndexList, setFilterIndexList] = useAtom(jotaiFilterIndexList);

  const [personalizedLists, setPersonalizedLists] = useState([]); // list of personlized lists

  const [personlizedMode, setPersonlizedMode] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const [allSelectedProp, setAllSelectedProp] = useAtom(jotaiAllSelectedProp);
  // std filter

  const [selectedFilterList, setSelectedFilterList] = useAtom(
    jotaiSelectedFilterList
  ); // List of dish names

  // personlized filter
  // const [filterPersonlizedIndex, setFilterPersonlizedIndex] = useState(-1); // 8 default to ALL in backend'
  const [filterPersonlizedIndex, setFilterPersonlizedIndex] = useAtom(
    jotaiFilterPersonlizedIndex
  ); // 8 default to ALL in backend'

  const [filterPersonlizedName, setFilterPersonlizedName] = useAtom(
    jotaiFilterPersonlizedName
  );
  // planning lists
  const [planningList, setPlanningList] = useState([]);
  const [planninggListToggle, setPlanningListToggle] = useState(false);

  const DishPaths = {
    topHundred: "/getASnackSwe100Pop",
    vegetarian: "/vegetarianpop_dishes",
    quick: "/dishmodels_100quick",
    filterTest: "/dishmodels_swepop100_with_filter",
    filteredTop10: "/dishmodels_swepop10_with_filter",
  };

  const handleClose = (value) => {
    setOpenFilterDialog(false);
  };

  // add dishes, list creation should occurr when first item is added
  const addPlanningList = async () => {
    const response = await fetch("/addPlanningList", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inputName: deish.trim() }),
    });

    if (!response.ok) {
      throw new Error("addPlanningList error");
    } else {
      // updatera lista som har kolla på vald eller inte vald favorit
    }
  };

  // remove dishes, list deletion should occurr when last item is deleted
  const deletePlanningList = async () => {
    const response = await fetch(
      `/deletePlanningList?dishName=${deish.trim()}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Data coud not be fetched (deleteDish)!");
    } else {
      // updatera lista som har kolla på vald eller inte vald favorit
      console.log(`${deish} tillagt i favoriter`);
    }
  };

  const checkIfLoggedIn = async () => {
    const response = await fetch("/checkLoginStatus", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      setLoggedInState(false);
    } else {
      setLoggedInState(true);
    }
  };

  const fetchDataTop10 = async (path, filter) => {
    const fetchData = path + `?filter=${filter}`;
    var response = await fetch(fetchData);
    if (!response.ok) {
      throw new Error("Data could not be fetched (1)!");
    } else {
      const json = await response.json();
      scrambleNSet(json);
    }
  };

  const fetchData = async (path, filter, dish) => {
    console.log("kommer jag hit??");
    var response = await fetch(path + `?filter=${filter}&dish=${dish}`);

    if (!response.ok) {
      throw new Error("Data could not be fetched (2)!");
    } else {
      const json = await response.json();
      scrambleNSet(json);
    }
  };

  // const DishCalls = {
  //   filterTest: (filterIndex, dishIndex) => {
  //     fetchData(DishPaths.filterTest, filterIndex, dishIndex);
  //   },
  //   filteredTop10: (idx) => {
  //     fetchDataTop10(DishPaths.filteredTop10, idx);
  //   },
  // };

  // Fisher Yates scramble
  const scrambleList = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const scrambleNSet = (data) => {
    //console.log(`data hejehje: ${data}`);
    if (data && data.length === 0) {
      setDishList([]);
      setDeish("Ops! Saknas maträtter med dessa byggsatser");
      return;
    }
    const newList = scrambleList(data);
    setDishList(newList); // set list with foods
    // set initial food forslag
    if (personlizedMode) {
      setDeish(newList[0].name); // happens to be .name here aswell
    } else {
      setDeish(newList[0].name);
    }
  };

  const onClickRandom = (event) => {
    var newDish = null;
    if (index + 1 > dishList.length - 1) {
      setIndex(0);
      newDish = dishList[0].name;
      setDeish(newDish);
    } else {
      newDish = dishList[index + 1].name;
      setDeish(newDish);
      setIndex(index + 1);
    }

    // planningDish toggle logic
    setPlanningListToggle(false);
    if (planningList.indexOf(newDish) !== -1) {
      setPlanningListToggle(true);
    } else {
      setPlanningListToggle(false);
    }
  };

  const onClickSavePlanning = (event) => {
    setPlanningListToggle((oldValue) => !oldValue);
    if (planningList.indexOf(deish) !== -1) {
      setPlanningList((oldList) => oldList.filter((item) => item !== deish)); // should get data from backend
      deletePlanningList();
    } else {
      setPlanningList((oldList) => [...oldList, deish]); // should get data from backend
      addPlanningList();
    }
  };

  // TODO: Vad är en sanna källan till filtrena?? Se över det och bestäm en källa som styr!
  const updateCurrentFilter = async (filterName, mode) => {
    // Speical case for when personlizedList contains Objects and not strings
    let personlizedFilterIndex = -1;

    let counter = 0;
    personalizedLists.forEach((item) => {
      if (item.listName === filterName) {
        personlizedFilterIndex = counter;
      }
      counter++;
    });

    // IF 8 (All) is added, remove all items in selectedFilterList, if any other value is added remove number 8
    const willRemove =
      selectedFilterList.indexOf(filterName) !== -1 ||
      (personlizedFilterIndex !== -1 &&
        personlizedFilterIndex === filterPersonlizedIndex); // bad names! Change!
    if (willRemove) {
      console.log("Will remove");

      // select or unselect All filter
      if (filterName === "Alla") {
        setFilterIndexList({ type: "reset", payload: [] });
        setSelectedFilterList({ type: "reset", payload: [] });
        setFilterPersonlizedIndex(-1);
        setAllSelectedProp(!allSelectedProp);
        setFilterPersonlizedName("");
      } else {
        if (mode === "personlized") {
          setFilterPersonlizedIndex(-1);
          setFilterPersonlizedName("");
        } else {
          // std
          setSelectedFilterList({ type: "remove", payload: filterName });
          setFilterIndexList({
            type: "remove",
            payload: filter.indexOf(filterName),
          });
          setPersonlizedMode(false);
          setFilterPersonlizedName("");
        }
      }
      console.log(`Remove filterIndexList: ${filterIndexList}`);
    } else {
      console.log("Will add");
      if (mode === "personlized") {
        setSelectedFilterList({ type: "reset", payload: [] });
        setFilterIndexList({ type: "reset", payload: [] });

        setFilterPersonlizedIndex(personlizedFilterIndex);
        setFilterPersonlizedName(filterName);
        setPersonlizedMode(true);

        if (allSelectedProp) {
          setAllSelectedProp(false);
        }
      } else {
        // std mode
        setFilterPersonlizedName("");
        if (filterName === "Alla") {
          setSelectedFilterList({ type: "reset", payload: [] });
          setFilterIndexList({ type: "reset", payload: [] });
          setFilterPersonlizedIndex(-1);
          setAllSelectedProp(!allSelectedProp);
        } else {
          setFilterPersonlizedIndex(-1);
          setSelectedFilterList({ type: "append", payload: filterName });
          setFilterIndexList({
            type: "append",
            payload: filter.indexOf(filterName),
          });
        }
      }
    }
  };

  const getLists = async () => {
    if (loggedInState) {
      const tmpLists = await getList();
      if (tmpLists) {
        setLoggedInState(true);
        setPersonalizedLists(tmpLists);
      } else {
        setPersonalizedLists([]); // clear list if logged out
        setLoggedInState(false);
      }
    }
  };

  const getPersonlizedDishes = async () => {
    const tempDishList = await getDishes(filterPersonlizedName); // personlized dishes fetch
    scrambleNSet(tempDishList);
  };

  useEffect(() => {
    if (filterPersonlizedIndex === -1) {
      fetchDataTop10(DishPaths.filteredTop10, filterIndexList);
    } else {
      getPersonlizedDishes();
    }

    checkIfLoggedIn();
    if (loggedInState) {
      getLists();
    } else {
      setPersonalizedLists([]);
    }
  }, [loggedInState, filterIndexList, filterPersonlizedIndex]); // if logged out the personlized lists needs to be cleared

  const handleFilterClick = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  console.log(`filterIndexList: ${filterIndexList}`);
  console.log(`selectedFilterList ${selectedFilterList}`);
  console.log(`filterPersonlizedName: ${filterPersonlizedName}`);
  return (
    <Box sx={{ textAlign: "center" }}>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={openFilterDialog}
      >
        <DialogTitle>Välj filter</DialogTitle>
        <FilterDialog
          stdFilter={filter}
          personlizedList={personalizedLists}
          updateCurrentFilter={updateCurrentFilter}
          currentFilter={selectedFilterList}
          allSelectedProp={allSelectedProp}
          filterPersonlizedIndex={filterPersonlizedIndex}
        />
      </Dialog>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        sx={{ height: "30vh", pt: "17vh" }}
      >
        <DishText isMobile={false} text={deish} />
        <FilterTexts
          filterList={selectedFilterList}
          filterPersonlizedName={filterPersonlizedName}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <ThemeProvider theme={megaCustom}>
            <Button
              variant="outlined"
              sx={{ borderRadius: 10, borderWidth: 1 }}
              onClick={() => onClickRandom()}
              disableRipple
            >
              Ny rätt
            </Button>
            {loggedInState ? (
              <Button
                variant="outlined"
                sx={{ borderRadius: 10, borderWidth: 1 }}
                onClick={() => onClickSavePlanning()}
                disableRipple
              >
                {planninggListToggle ? (
                  <PlaylistRemoveIcon />
                ) : (
                  <PlaylistAddIcon />
                )}
              </Button>
            ) : null}
          </ThemeProvider>
        </Box>
        <Box sx={{ py: 2, pl: 1 }}>
          <Button href={`https://google.se/search?q=${deish} recept`}>
            Googla rätten
          </Button>
          <Button onClick={handleFilterClick}>Filter</Button>
        </Box>
      </Box>
    </Box>
  );
}
