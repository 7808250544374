import {
  List,
  Button,
  Box,
  ListItemButton,
  TextField,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useWindowDimensions from "../hook/useWindowDimensions";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const mobileSX = { width: "70vw" };
const desktopSX = { pt: 4 };

const ListList = (props) => {
  const customTheme = useTheme();
  const { width, height } = useWindowDimensions();
  const mobileModeOn = width < 767;

  return (
    <Box sx={mobileModeOn ? mobileSX : desktopSX}>
      <Grid container>
        <Grid xs={8}>
          <TextField
            label="Namge lista"
            onChange={(event) => {
              props.setListName(event.target.value);
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                props.createList();
                props.setListName("");
              }
            }}
            sx={{ width: "100%" }}
            value={props.listName}
            inputProps={{ autoCapitalize: "none" }}
          />
        </Grid>
        <Grid xs={4}>
          <Button
            onClick={() => {
              props.createList();
              props.setListName("");
            }}
            sx={{
              borderRadius: 10,
              mt: 1,
              color: customTheme.palette.secondary.main,
            }}
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <List>
        {props.lists.map((item) => (
          <ListItemButton
            onClick={() => {
              props.onListClick(item.listName);
            }}
            key={item.listName}
            selected={item.listName === props.selectedList}
          >
            {" "}
            {item.listName}{" "}
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export { ListList };
