import { atom } from "jotai";

const loggedIn = atom(false);
const selecList = atom("");
const admin = atom(false);

const initFilterIngredient = {
  selectAll: true,
  selectedIndexList: [],
  selectedNameList: [],
};

const arrayHandler = (newArray, action) => {
  switch (action.type) {
    case "reset":
      newArray = action.payload; // Replace with the new array
      break;

    case "append":
      newArray = [...newArray, action.payload]; // Append a new child
      break;

    case "remove":
      newArray = newArray.filter((child) => child !== action.payload); // Remove the specified child
      break;

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newArray;
};

const filterIngredient = atom(initFilterIngredient);

// Read write atoms for filter operations

const jotaiFilterIndexList = atom(
  (get) => get(filterIngredient).selectedIndexList,
  (get, set, action) => {
    set(filterIngredient, (prev) => {
      return {
        ...prev,
        selectedIndexList: arrayHandler([...prev.selectedIndexList], action),
      };
    });
  }
);

const jotaiSelectedFilterList = atom(
  (get) => get(filterIngredient).selectedNameList,
  (get, set, action) => {
    set(filterIngredient, (prev) => {
      return {
        ...prev,
        selectedNameList: arrayHandler([...prev.selectedNameList], action),
      };
    });
  }
);

const jotaiFilterPersonlizedName = atom("");
const jotaiAllSelectedProp = atom(true);
const jotaiFilterPersonlizedIndex = atom(-1);

export {
  loggedIn,
  selecList,
  admin,
  jotaiFilterIndexList,
  jotaiFilterPersonlizedName,
  jotaiSelectedFilterList,
  jotaiAllSelectedProp,
  jotaiFilterPersonlizedIndex,
};
