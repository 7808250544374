import { Typography } from "@mui/material";

function DishText(props) {
  return (
    <Typography variant="h2" component="div" style={{ textAlign: "center" }}>
      {props.text}
    </Typography>
  );
}

export default DishText;
