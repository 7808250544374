import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
/**
 *
 * TODO:
 * - Load user specific filters. Backend should append personlized and standard filters. Here only a list should be handled
 * -
 */

export default function FilterDialog(props) {
  const {
    stdFilter,
    personlizedList,
    updateCurrentFilter,
    currentFilter,
    allSelectedProp,
    filterPersonlizedIndex,
  } = props;

  var personlizedListNew = [];

  personlizedList.forEach((item) => {
    personlizedListNew.push(item.listName);
  });

  const handleToggle = (value, mode) => () => {
    updateCurrentFilter(value, mode);
  };

  return (
    <List sx={{ height: "70vh" }}>
      <ListItem disablePadding>
        <ListItemButton
          role={undefined}
          onClick={handleToggle("Alla", "std")}
          dense
        >
          <ListItemText primary={"Alla"} />
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={allSelectedProp}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      {stdFilter.map((item) => {
        const labelId = `checkbox-list-label-${item}`;
        return (
          <ListItem key={item} disablePadding>
            <ListItemButton
              disabled={allSelectedProp}
              role={undefined}
              onClick={handleToggle(item, "std")}
              dense
            >
              <ListItemText id={labelId} primary={item} />
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    currentFilter.indexOf(item) !== -1 || allSelectedProp
                  }
                  tabIndex={-1}
                  disableRipple
                  unselectable="off"
                  disabled={allSelectedProp}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        );
      })}
      {personlizedListNew.map((item) => {
        const labelId = `checkbox-list-label-${item}`;
        return (
          <ListItem key={item} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(item, "personlized")}
              dense
            >
              <ListItemText id={labelId} primary={item} />
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    personlizedListNew.indexOf(item) === filterPersonlizedIndex
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
