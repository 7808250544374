import { createTheme } from "@mui/material/styles";
import {
  formLabelClasses,
  TextField,
  ListItemText,
  ListItemButton,
  Dialog,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const customButtonStyle = {
  backgroundColor: "rgba(236,45,1,0.6)",
  color: "white",
  borderRadius: "8px",
  // Add any other styles you want
};

export const textFieldTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule

          [`&.${formLabelClasses.focused}`]: {
            color: "rgba(236,45,1,0.6)",
          },
        },
      },
    },
  },
});

// export const CustomTextField = styled(TextField)({
//   "& .MuiOutlinedInput-input": {
//     color: "#B2BAC2",
//   },
//   "& label.Mui-focused": {
//     color: "#A0AAB4",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#B2BAC2",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#E0E3E7",
//     },
//     "&:hover fieldset": {
//       borderColor: "#B2BAC2",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#6F7E8C",
//     },
//   },
// });

export const CustomListItemText = styled(ListItemText)({
  "& .MuiListItemText-secondary": {
    color: "#B2BAC2",
  },
  "& .MuiListItemText-primary": {
    color: "#B2BAC2",
  },
});

export const CustomListItemButton = styled(ListItemButton)({
  "&.MuiListItemButton-root": { color: "#B2BAC2" },
  "&:hover": {
    backgroundColor: "#6F7E8C",
  },
  "&.Mui-selected": {
    backgroundColor: "#6F7E8C",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
});

export const CustomMenuButton = styled(Button)({
  "&.MuiButton-text": { color: "#B2BAC2" },
  "&:hover": {
    backgroundColor: "#6F7E8C",
  },
  "&.Mui-selected": {
    backgroundColor: "#6F7E8C",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
});

export const CustomDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "15%", // Set your width here
    },
  },
});

export const CustomTextField = styled(TextField)({
  "&.MuiTextField-root": { margin: -8 },
});
