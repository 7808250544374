import { useEffect, useState, Fragment } from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { useAtom } from "jotai";
import { loggedIn, selecList } from "./JotaiStore";
import { getDishes, getList } from "./common/httpRequests";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DishList } from "./components/DishList";
import { useTheme, Box, TextField } from "@mui/material";

// Mobile specifics
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useWindowDimensions from "./hook/useWindowDimensions";
import { ListList } from "./components/ListList";

/**
  TODO: See if https://serpapi.com/images-results can be implemented to add images for "well known" images (limit these to the standard dishes and not personlized lists)
 */

export default function ListsMobile() {
  const [dishes, setDishes] = useState([]); // list of dishes
  //const [selectedList, setSelectedList] = useState("");
  const [lists, setLists] = useState([]); // list of lists
  const [dishName, setDishName] = useState(""); // text field
  const [listName, setListName] = useState(""); // text field
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editableText, setEditableText] = useState("");
  const [openPopper, setOpenPopper] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedList, setSelectedList] = useAtom(selecList);
  const [loggedInState, setLoggedInState] = useAtom(loggedIn);
  const customTheme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Mobile specifics
  const [pageSwitch, setPageSwitch] = useState(false);
  const { width, height } = useWindowDimensions();

  // dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleVertIconClick = (event, item, index) => {
    // reset
    handleClosetest();

    setCurrentIndex(index); // give better name, currently selected item for which the options menu has been opened
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
    setOpenPopper(true);
  };

  const handleClosetest = () => {
    setAnchorEl(null);
    setEditMode(false);
    setOpenPopper(false);
  };

  const id = openPopper ? "simple-popover" : undefined;

  const createDish = async () => {
    const response = await fetch("/createDish", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inputName: dishName.trim(), selectedList }),
    });

    if (!response.ok) {
      const json = await response.json();
      if (json !== undefined && json.details !== undefined) {
        enqueueSnackbar(json.details.field, { variant: "error" });
      } else {
        enqueueSnackbar("Fel data från severn: Ber om ursäkt", {
          variant: "error",
        });
      }
    } else {
      setDishes((oldDishes) => [...oldDishes, { name: dishName.trim() }]);
    }
  };

  const pressEditButton = async (index) => {
    setOpenPopper(false);
    setEditMode(true);
    setEditableText(selectedItem.name);
  };

  const editDish = async () => {
    if (selectedItem.name === editableText) {
      return;
    }
    const encodedUri = `/updateDish?dishName=${encodeURIComponent(
      selectedItem.name
    )}&listName=${encodeURIComponent(
      selectedList
    )}&newDishName=${encodeURIComponent(editableText)}`;

    const response = await fetch(encodedUri, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Data coud not be fetched (deleteDish)!");
    } else {
      // Update a single item in the array within the state
      setDishes(
        dishes.map((item) => {
          if (selectedItem.name === item.name) {
            let newItem = item;
            newItem.name = editableText;
            return newItem;
          } else {
            return item;
          }
        })
      );
    }
  };

  const deleteDish = async () => {
    if (selectedItem !== null && selectedItem.name !== "") {
      const encodeUri = `/removeDish?dishName=${encodeURIComponent(
        selectedItem.name
      )}&listName=${encodeURIComponent(selectedList)}`;
      const response = await fetch(encodeUri, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Data coud not be fetched (deleteDish)!");
      } else {
        setDishes((oldDishes) =>
          dishes.filter((item) => item.name !== selectedItem.name)
        );
      }
    } else {
      throw new Error("Data coud not be fetched (deleteDish)!");
    }

    handleClosetest();
  };

  const deleteList = async () => {
    const encodeUri = `/deleteList?id=${encodeURIComponent(selectedList)}`;
    const response = await fetch(encodeUri, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Data coud not be fetched (deleteList)!");
    } else {
      setLists((oldList) =>
        oldList.filter((item) => item.listName !== selectedList)
      );
      // Mobile specific
      togglePageChange();
      setListName("");
      setSelectedList("");
      setDishes([]);
    }
  };

  const askToDeleteList = async () => {
    enqueueSnackbar("Vill du verkligen ta bort listan", {
      variant: "warning",
      action: (key) => (
        <Fragment>
          <Button
            size="small"
            variant="error"
            onClick={() => {
              setIsDeleted(true);
              setDeleteToggle(!deleteToggle);
              closeSnackbar(key);
            }}
          >
            Ja, ta bort
          </Button>
          <Button
            variant="error"
            size="small"
            onClick={() => {
              setIsDeleted(false);
              closeSnackbar(key);
            }}
          >
            Nej
          </Button>
        </Fragment>
      ),
    });
  };

  const createList = async () => {
    const response = await fetch("/createList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inputName: listName.trim() }),
    });

    if (!response.ok) {
      const json = await response.json();
      if (json !== undefined && json.details !== undefined) {
        enqueueSnackbar(json.details.field, { variant: "error" });
      } else {
        enqueueSnackbar("Fel data från severn: Ber om ursäkt", {
          variant: "error",
        });
      }
    } else {
      setLists((oldList) => [...oldList, { listName: listName.trim() }]);
    }
  };

  const getLists = async () => {
    const tmpLists = await getList();
    if (tmpLists) {
      setLoggedInState(true);
      setLists(tmpLists);
      if (!selectedList) {
        setSelectedList(tmpLists.length > 0 ? tmpLists[0].listName : "");
        if (tmpLists.length > 0) {
          setSelectedList(tmpLists[0].listName);
          const tmpDishes = await getDishes(tmpLists[0].listName);
          setDishes(tmpDishes);
        } else {
          setSelectedList("");
        }
      }
    } else {
      setLoggedInState(false);
    }
  };

  useEffect(() => {
    if (isDeleted === true) {
      deleteList();
      setIsDeleted(false);
      return;
    }
    getLists();
  }, [deleteToggle]);

  const onListClick = async (name) => {
    setSelectedList(name);
    const tmpDishes = await getDishes(name);
    // mobile
    togglePageChange();
    setDishes(tmpDishes);
  };

  // mobile
  const togglePageChange = () => {
    setPageSwitch((prev) => !prev);
  };
  const resHeight = (height - 220) * 0.8;

  return loggedInState ? (
    <Box
      display="fixed"
      sx={{
        width: "100%",
        pt: 2,
      }}
    >
      {pageSwitch ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="left"
            alignItems="center"
            sx={{ pb: 2, ml: -2 }}
          >
            <Button
              onClick={() => {
                togglePageChange();
              }}
              sx={{ color: customTheme.palette.secondary.main }}
            >
              <ArrowBackIcon />
            </Button>
            <Typography variant="h5" component="div">
              {" "}
              {selectedList}{" "}
            </Typography>

            <Button
              onClick={() => {
                askToDeleteList();
              }}
              sx={{
                borderRadius: 10,
                color: customTheme.palette.secondary.main,
              }}
            >
              <Delete />
            </Button>
          </Box>
          <Box>
            <TextField
              label="Lägg till maträtt"
              onChange={(event) => {
                setDishName(event.target.value);
              }}
              inputProps={{ autoCapitalize: "none" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  createDish();
                  setDishName("");
                }
              }}
              value={dishName}
              sx={{ width: "40vw", marginLeft: "5%" }}
            />
            <Button
              onClick={() => {
                createDish();
                setDishName("");
              }}
              color="inherit"
              sx={{ mt: 1, borderRadius: 10 }}
            >
              <AddIcon />
            </Button>
          </Box>
          <DishList
            dishes={dishes}
            editMode={editMode}
            currentIndex={currentIndex}
            handleCloseTextField={null}
            setEditableText={setEditableText}
            editDish={editDish}
            setEditMode={setEditMode}
            handleVertIconClick={handleVertIconClick}
            id={id}
            openPopper={openPopper}
            anchorEl={anchorEl}
            onClose={handleClosetest}
            handleClosetest={handleClosetest}
            pressEditButton={pressEditButton}
            deleteDish={deleteDish}
          />
          <Box sx={{ height: 120, bgcolor: "white" }} />{" "}
          {/** space for creating an offset against the bottom navigation bar */}
        </Box>
      ) : (
        <ListList
          onListClick={onListClick}
          setListName={setListName}
          createList={createList}
          lists={lists}
          selectedList={selectedList}
          listName={listName}
        />
      )}

      <Outlet />
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="left"
      minHeight="50%"
      sx={{ width: "100%", pl: "9%", pt: 10 }}
    >
      <Typography variant="h4"> Listor </Typography>
      <Typography variant="p" sx={{ pt: 2 }}>
        Du behöver skapa ett konto, eller logga in{" "}
        <NavLink to="/profile">här</NavLink>
      </Typography>
    </Box>
  );
}
