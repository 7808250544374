import {
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { loggedIn, admin } from "./JotaiStore";
import { useAtom } from "jotai";
import { useLocation, useNavigate } from "react-router-dom";
import { customButtonStyle } from "./styles/buttons";
import { Box } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

var didOnce = false;

export default function Profile() {
  const [accountName, setAccountName] = useState("");
  const [createAccountName, setCreateAccountName] = useState("");
  const [loginJWT, setLoginJWT] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loggedInState, setLoggedInState] = useAtom(loggedIn);
  const [adminState, setAdminState] = useAtom(admin);
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!didOnce) {
      // safeguard
      checkIfLoggedIn(); // Dangerous to have this line here since the request if made hundreds of times if kept unchecked
      didOnce = true;
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   *  Make login request. Return a token
   */

  const checkIfLoggedIn = async () => {
    const response = await fetch("/checkLoginStatus", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      setLoggedInState(false);
    } else {
      const json = await response.json();
      if (json.loggedIn) {
        setLoggedInState(true);
      } else {
        setLoggedInState(false);
      }
    }
  };

  const login = async () => {
    var response = await fetch("/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed (e.g., authorization)
      },
      body: JSON.stringify({ username: accountName, password }),
    });
    if (!response.ok) {
      setLoginFailed(true);
      setLoggedInState(false);
    } else {
      const json = await response.json();
      if (json.loggedIn) {
        if (json.admin) {
          setAdminState(true);
        }

        setLoggedInState(true);
        navigate("/");
      } else {
        setLoginFailed(true);
        setLoggedInState(false);
      }
    }
  };

  const logout = async () => {
    var response = await fetch(`/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    setLoggedInState(false);
    setAdminState(false);
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      navigate("/");
      return response.json();
    }
  };

  const createAccount = async () => {
    var response = await fetch("/createProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers as needed (e.g., authorization)
      },
      body: JSON.stringify({ username: createAccountName, password }),
    });
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  const deleteAccount = async () => {
    console.log(`username ${accountName}`);
    var response = await fetch(`/deleteProfile?id=${accountName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };

  let createAccountState = location.state
    ? location.state.createAccount
    : false;

  // tre fall
  /***
   * 1. logga in
   * 2. skapa konto (skicka mejl)
   * 3. ange lösenord vid kontoskapande
   */

  console.log(`loggedInState: ${loggedInState}`);
  return (
    <Box
      minHeight="50%"
      sx={{
        pt: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {createAccountState ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="flex-start"
          sx={{
            width: "50vw",
            "@media (min-width:600px)": {
              width: "30vw",
            },
          }}
        >
          <Typography variant="h4" component="div" sx={{ textAlign: "left" }}>
            {" "}
            Skapa konto
          </Typography>
          <TextField
            label="E-mejl"
            sx={{ my: "4%" }}
            onChange={(event) => {
              setCreateAccountName(event.target.value);
            }}
            inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
          />
          <TextField
            label="Lösenord"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
          />
          <Button
            style={customButtonStyle}
            onClick={() => {
              createAccount();
            }}
            sx={{ my: "4%" }}
            variant="contained"
          >
            {" "}
            Skapa konto{" "}
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="flex-start"
          sx={{
            width: "50vw",
            "@media (min-width:600px)": {
              width: "30vw",
            },
          }}
        >
          {loggedInState ? (
            <Button
              onClick={() => {
                logout();
              }}
              variant="contained"
              sx={{ my: "4%" }}
            >
              {" "}
              Logga ut{" "}
            </Button>
          ) : (
            <Fragment>
              <Typography
                variant="h4"
                component="div"
                sx={{ textAlign: "left" }}
              >
                {" "}
                Logga in
              </Typography>

              <TextField
                label="Användarnamn"
                onChange={(event) => {
                  setAccountName(event.target.value);
                }}
                sx={{ my: "4%" }}
                inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
              />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Lösenord
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  inputProps={{ autoCapitalize: "none", autoCorrect: "off" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Button
                onClick={() => {
                  login();
                }}
                variant="contained"
                sx={{ my: "4%" }}
              >
                {" "}
                Nästa{" "}
              </Button>
              <Typography variant="p" component="div">
                {`${String.fromCodePoint(
                  0x00002b50
                )} Konton och personliga listor är tillgängligt för beta-testare ${String.fromCodePoint(
                  0x00002b50
                )}`}
              </Typography>
            </Fragment>
          )}
        </Box>
      )}
    </Box>
  );
}
