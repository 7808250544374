import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import DropboxResetPasswordEmail from "./MyEmail";
import { Provider } from "jotai";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { customTheme } from "./styles/theme";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider>
      <BrowserRouter>
        <ThemeProvider theme={customTheme}>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

/** 
<Provider>
      <BrowserRouter>
      <App />
        
      </BrowserRouter>
    </Provider>
    */
/**
 * <DropboxResetPasswordEmail resetPasswordLink="https://google.se" />
 */
