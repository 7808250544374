import { createTheme } from "@mui/material";

const commonPalette = {
  palette: {
    mode: "light",
    primary: {
      main: "rgba(236,45,1,0.6)",
    },
    secondary: {
      main: "rgba(0,0,0,1)",
    },
    text: {
      primary: "rgba(0,0,0,0.9)",
      secondary: "rgba(0,0,0,0.9)",
    },
  },
};
const megaCustom = createTheme({
  typography: {
    fontFamily: "serif",
    button: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
  },
  ...commonPalette,
});

const customTheme = createTheme({
  typography: {
    fontFamily: "serif",
    body1: {
      fontSize: "1rem",
      "@media (min-width:600px)": {
        fontSize: "1rem",
      },
    },
    body: {
      fontSize: "1.2rem",
      "@media (min-width:600px)": {
        fontSize: "5rem",
      },
    },
    button: {
      fontSize: "0.875rem",
      "@media (min-width:600px)": {
        fontSize: "1rem",
      },
      default: "0.875rem",
    },
    // h6: {
    //   fontSize: "1.2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "5rem",
    //   },
    // },
    // h5: {
    //   fontSize: "1.2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "5rem",
    //   },
    // },
    // h4: {
    //   fontSize: "1.2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "5rem",
    //   },
    // },
    // h3: {
    //   fontSize: "1.2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "5rem",
    //   },
    // },
    h2: {
      fontSize: "2.2rem",
      "@media (min-width:600px)": {
        fontSize: "3.75rem",
      },
    },
    // h1: {
    //   fontSize: "1.2rem",
    //   "@media (min-width:600px)": {
    //     fontSize: "5rem",
    //   },
    // },
  },
  ...commonPalette,
});

const customThemeDark = createTheme({
  typography: {
    fontFamily: "serif",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "rgba(236,45,1,0.6)",
    },
    secondary: {
      main: "rgba(0,0,0,1)",
    },
    text: {
      primary: "rgba(255,255,255,1)",
      secondary: "rgba(255,255,255,1)",
    },
  },
});

export { customTheme, megaCustom };

/**
 * components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
 */
