import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Logo(props) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <Typography
        variant={props.isMobile ? "h5" : "h4"}
        component="div"
        sx={{ color: "black" }}
      >
        {String.fromCodePoint(0x0001f345)}
      </Typography>
      <Typography
        variant={props.isMobile ? "h7" : "h5"}
        component="div"
        sx={{ color: "black" }}
      >
        Vad ska jag laga till middag?
      </Typography>
      <Typography
        variant={props.isMobile ? "h5" : "h4"}
        component="div"
        sx={{ color: "black" }}
      >
        {String.fromCodePoint(0x0001f345)}
      </Typography>
    </Box>
  );
}

export default Logo;
