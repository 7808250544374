import { Typography } from "@mui/material";

const FilterTexts = (props) => {
  let newList = props.filterList.map((item) => `${item} + `); // add + after each dish
  const lastIndex = newList.length - 1;

  if (newList.length > 0) {
    // remove + from the last dish
    newList[lastIndex] = newList[lastIndex].substring(
      0,
      newList[lastIndex].length - 2
    );
  } else if (props.filterPersonlizedName !== "") {
    newList = props.filterPersonlizedName;
  } else {
    newList = "";
  }

  return (
    <Typography
      variant="h6"
      component="div"
      style={{ textAlign: "center", opacity: 0.6 }}
    >
      {newList}
    </Typography>
  );
};

export { FilterTexts };
