/**
 * TODO:
 *
 * PRIO:
 * - Horizontell scroll dyker upp på Listor-sidan (i mobil mode)
 * - Scroll funkar inte bra på mobil. Det scrollar på alla sidor när det inte behövs. Det behövs en utfyllnad i slutet på ListMobile-sidan för att de sista element ska synas.
 * - Sätt en slumpaknapp vid en lista-titel
 * - Om två maträtter har sammma namn i en lista och man börjar att editera så blir det knasigt
 * - Spara info kring om man är inne i en lista på ListMobile eller inte. Man vill komma tillbaka till vyn som man sist besökte.
 * NON-PRIO:
 * - Layout på Slumpasidan är inte bra när man roterar mobil. Se över övriga sidor också.
 * - Logiken, på Slumpsidan, som har koll på vilka maträtter som ingår i Favoriter sköts nu av klienten. Måste skötas av servern. Blir fel om man laddar om sidan. Då har klienten inte längre koll.
 * - Lägg sociala medier logo under meny (mobil)
 * - Progress loader margin-top (thin and 100%width)
 * - Appen är ca 4MB i produktion vilket inte är bra. Optimera detta.
 * - Se över backend routing: https://medium.com/@marwan.zaarab/deploying-a-react-app-with-multiple-routes-using-express-static-files-e3373c53fe94 kan vara intressant!
 * - Skriv om List och ListMobile för att dela mera kod (påbörjad!)
 * - När animationer är aktiverade för Three vertices knappen så märker man en bugg. När edit-mode aktiveras så ser man popover-rutan uppe i vänstra hörnet. Den tappar sin position när Three vertices knappen försvinner.
 * - Lägg till hints-label över t.ex ta bort knapp
 * - Uppgradera Mongodb version på servern. Ställ "Is it possible to update MongoDB between major version without loosing data held in the database?" til chatGPT för vägledning.
 *   Just nu är Mongodb på macbooken v7 (8 är senaste) och version 6 körs på digital ocean servern.
 */

import { useEffect, useRef } from "react";
import React from "react";
import Popper from "@mui/material/Popper";
import {
  Avatar,
  Button,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList,
  Typography,
} from "@mui/material";
import Footer from "./Footer";
import Logo from "./Logo";
import Generator from "./Generator";
import {
  NavLink,
  Link,
  useLocation,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Lists from "./Lists";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { useAtom } from "jotai";
import {
  loggedIn,
  admin,
  selecList,
  jotaiFilterPersonlizedIndex,
  jotaiFilterPersonlizedName,
} from "./JotaiStore";
import Profile from "./Profile";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ListIcon from "@mui/icons-material/List";
import useWindowDimensions from "./hook/useWindowDimensions";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { Box, Container, AppBar, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ListMobile from "./ListMobile";

// 2898 x 688

const DEBUG = "development" === process.env.npm_config_name || false;

if (!DEBUG) {
  console.log = () => {};
}

function App() {
  const navigate = useNavigate();
  const [loggedInState, setLoggedInState] = useAtom(loggedIn);
  const [adminState, setAdminState] = useAtom(admin);
  const [selectedList, setSelectedList] = useAtom(selecList);
  const [, setFilterPersonlizedIndex] = useAtom(jotaiFilterPersonlizedIndex); // 8 default to ALL in backend'

  const [, setFilterPersonlizedName] = useAtom(jotaiFilterPersonlizedName);
  const location = useLocation();
  //popover menu from avatar
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { width, height } = useWindowDimensions();

  const mobileModeOn = width < 767;

  const pushPageLoadCount = async () => {
    var response = await fetch(MonitorPaths.countPageLoad, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response;
    }
  };

  const pushLogin = (event) => {
    navigate("/profile");
    handleClose(event);
  };

  // Not used at thwe moment
  const pushCreateAccount = (event) => {
    navigate("/profile", { state: { createAccount: true } });
    handleClose(event);
  };

  const pushLogout = (event) => {
    logout();
    handleClose(event);
  };

  const logout = async () => {
    var response = await fetch(`/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    setFilterPersonlizedName("");
    setFilterPersonlizedIndex(-1);
    setLoggedInState(false);
    setAdminState(false);
    setSelectedList("");
    if (!response.ok) {
      throw new Error("Data coud not be fetched!");
    } else {
      navigate("/");
      return response.json();
    }
  };

  const MonitorPaths = {
    countPageLoad: "/countPageLoad",
  };

  useEffect(() => {
    pushPageLoadCount()
      .then((res) => {
        console.log(`${res}`);
      })
      .catch((e) => {
        console.log(`Error response of page load put: ${e}`);
      });
  }, []);

  // popover
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // poopover
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  //popover
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const mainContainerStyle = {
    overflow: mobileModeOn ? "auto" : "hidden",
    height: "97vh",
  };

  const theme = useTheme();
  console.log(`Mobile mode on : ${mobileModeOn}`);
  return (
    <Box sx={{ ...mainContainerStyle }}>
      <AppBar position="fixed" sx={{ bgcolor: "white" }}>
        <Container maxWidth="xl">
          <Toolbar>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={mobileModeOn ? "center" : "space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Logo isMobile={mobileModeOn} />
              {mobileModeOn ? null : (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: 300,
                    minWidth: "40px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      borderRadius: 10,
                      width: 110,
                      textAlign: "center",
                    }}
                    to="/"
                    component={NavLink}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "#fff",
                            background: theme.palette.primary.main,
                          }
                        : { color: "#545e6f", background: "#f0f0f0" }
                    }
                  >
                    Generator
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      borderRadius: 10,
                      width: 90,
                      textAlign: "center",
                    }}
                    to="lists"
                    component={NavLink}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "#fff",
                            background: theme.palette.primary.main,
                          }
                        : { color: "#545e6f", background: "#f0f0f0" }
                    }
                  >
                    Listor
                  </Typography>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <Avatar alt="Cindy Baker">
                      <PersonOutline
                        style={loggedInState ? { color: "green" } : null}
                      />
                    </Avatar>
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            {loggedInState ? (
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                {adminState ? (
                                  <MenuItem onClick={pushCreateAccount}>
                                    Skapa konto
                                  </MenuItem>
                                ) : null}
                                <MenuItem onClick={pushLogout}>
                                  Logga ut
                                </MenuItem>
                              </MenuList>
                            ) : (
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem onClick={pushLogin}>
                                  Logga in
                                </MenuItem>
                              </MenuList>
                            )}
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      <Container sx={{ mt: 1 }}>
        <Routes>
          <Route path="/" element={<Generator />} />
          <Route
            path="lists"
            element={mobileModeOn ? <ListMobile /> : <Lists />}
          />
          <Route path="profile" element={<Profile />} />
        </Routes>
      </Container>
      {mobileModeOn ? (
        <BottomNavigation
          showLabels
          value={location.pathname}
          sx={{ bgcolor: "white", position: "fixed", bottom: 0, width: 1.0 }}
        >
          <BottomNavigationAction
            to="/"
            value="/"
            component={Link}
            label="Slumpa"
            icon={<ShuffleIcon />}
          />
          <BottomNavigationAction
            to="lists"
            value="/lists"
            component={Link}
            label="Listor"
            icon={<ListIcon />}
          />
          <BottomNavigationAction
            value="/profile"
            to="profile"
            component={Link}
            label="Profil"
            icon={<PersonOutline />}
          />
        </BottomNavigation>
      ) : null}
    </Box>
  );
}

export default App;

//  {mobileModeOn ? null : <Footer />}
