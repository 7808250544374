const getDishes = async (listName) => {
  console.log(`getDishes ${listName}`);
  const encodeUri = `/getDishes?id=${encodeURIComponent(listName)}`;
  const response = await fetch(encodeUri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Data coud not be fetched (getDishes) !");
  } else {
    const json = await response.json();
    return json.dishes;
  }
};

const getList = async () => {
  const response = await fetch("/getList", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    // Logged out!
    return null;
  } else {
    const json = await response.json();
    return json.lists;
  }
};

export { getDishes, getList };
