import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomTextField } from "../styles/buttons";
import useWindowDimensions from "../hook/useWindowDimensions";

const mobileStyle = {
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    width: "100%",
  },
};

const desktopStyle = {
  paper: { maxHeight: "60vh", overflowY: "auto" },
  list: {
    marginLeft: "5%",
  },
};

const DishList = (props) => {
  const customTheme = useTheme();
  const { width, height } = useWindowDimensions();
  const mobileModeOn = width < 767;
  return (
    <Paper
      sx={mobileModeOn ? mobileStyle.paper : desktopStyle.paper}
      elevation={0}
    >
      <List style={mobileModeOn ? null : desktopStyle.list}>
        {props.dishes.map((item, index) => (
          <Box key={item.name}>
            <ListItem
              sx={mobileModeOn ? mobileStyle.listItem : null}
              key={index}
            >
              {props.editMode && index === props.currentIndex ? (
                <ListItemText
                  primary={
                    <CustomTextField
                      variant="outlined"
                      onChange={(event) => {
                        props.setEditableText(event.target.value);
                      }}
                      hiddenLabel
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          props.editDish();
                          props.setEditMode(false);
                        }
                      }}
                      defaultValue={item.name}
                      sx={{ height: 20 }}
                      size="small"
                    />
                  }
                  sx={{ color: customTheme.palette.text.secondary }}
                />
              ) : (
                <ListItemText
                  primary={item.name}
                  sx={{ color: customTheme.palette.text.secondary }}
                />
              )}
              {props.editMode && index === props.currentIndex ? (
                <Box>
                  <CheckIcon
                    onClick={() => {
                      props.editDish();
                      props.setEditMode(false);
                    }}
                  />{" "}
                  <ClearIcon
                    onClick={() => {
                      props.handleClosetest();
                      props.setEditMode(false);
                    }}
                  />
                </Box>
              ) : (
                <MoreVertIcon
                  onClick={(event) =>
                    props.handleVertIconClick(event, item, index)
                  }
                />
              )}
            </ListItem>
          </Box>
        ))}
      </List>
      <Popper
        id={props.id}
        open={props.openPopper}
        anchorEl={props.anchorEl}
        onClose={props.handleClosetest}
        placement="right"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper sx={{ ml: -4, mt: 14 }}>
              <ClickAwayListener onClickAway={props.handleClosetest}>
                <MenuList autoFocusItem={props.openPopper}>
                  <MenuItem
                    onClick={() => props.pressEditButton(props.currentIndex)}
                  >
                    Editera
                  </MenuItem>

                  <MenuItem onClick={props.deleteDish}> Ta bort </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Paper>
  );
};

export { DishList };
